.subject-button {
    padding: 8px 2rem;
    border: 1px solid black;
    text-decoration: none;
    margin: 5px;
    font-size: 13px;
    font-family: "wfont1";
}

.subject-btn-container {
    gap: 10px;
    text-align: center;
}


.subject-button.selected {
    background: black;
    color: #FFFEFB;
    border: 1px solid black;
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
    .subject-button {
        padding: 4px 0.8rem;
    }
}