.team_main_img_wrapper{
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.team_main_img_wrapper img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: 0% 25%;
}

.team-section2 {
    min-height: auto;
    background-color: black;
    color: white;
    display: grid;
    align-items: center;
    font-size: 15px;
    text-align: center;
    padding: 1rem 2rem;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.team-section2 .row{
    margin-bottom: 0;
}

.team-section3 {
    display: flex;
    justify-content: space-between;
}

.left-div{
    flex: 3;
}
.right-div {
    flex: 1;
    text-align: right;
}