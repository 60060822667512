h1 {
    font-family: 'Playfair Display Variable', serif;
    font-weight: bold;
}

h4 {
    font-family: 'Playfair Display Variable', serif;
    font-weight: bold;
    font-size: 25px;
}

h5 {
    font-size: 25px;
    font-family: 'wfont1';
}

p {
    font-family: 'wfont2';
    font-size: 16px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

section {
    margin-top: 10%;
    margin-bottom: 10%;
}

.section1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
    background-image: url(../assets/home/Main1_Web.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 50%;
    margin-bottom: 0;
}

.section3 .black-wrapper .row {
    display: flex;
    flex-direction: row;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.section3 .black-wrapper .title {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
}

.section3 .image-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1280px;
    margin: auto;
}

.section3 .image-wrapper .row {
    margin: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.section3 .title {
    font-size: 20px;
}

.section3 .clients-partners-wrapper {
    display: flex;
    flex-direction: column;
}

.number {
    font-size: 30px;
}

.black-border {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.black-border .row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section4 {
    margin-bottom: 0;
}

.section4 .hear-from-wrapper {
    display: flex;
    justify-content: space-between;
}

.section4 .content-wrapper {
    align-items: center;
    text-align: center;
    padding: 1rem;
}

.section4 .gray-wrapper {
    background-color: #F0F0F0;
    color: black;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.section5{
    margin-top: 0;
    margin-bottom: 0;
}

.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #767676;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
}

.circle img {
    width: 60%;
    height: auto;
}

.clients-partners-title {
    font-weight: bold;
}

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .section1 {
        height: 450px;
    }

    .section3 .image-wrapper .row {
        margin: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
  
    }

    .section4{
        margin-bottom: 10%;
    }

    .section4 .hear-from-wrapper {
        display: flex;
        flex-direction: column;
    }

    .section4 .hear-from-wrapper .col {
        width: 100%;
    }

    .section4 {
        margin-bottom: 0px;
    }
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
    h4 {
        font-size: 19px;
    }

    h5 {
        font-size: 18px;
    }

    p {
        font-size: 13px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .section1 {
        background-image: none;
        height: 100%;
    }

    .section1 img {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .section3 .black-wrapper {
        background-color: white;
        color: black;
    }

    .section3 .black-wrapper .row {
        background-color: white;
        color: black;
        flex-direction: column;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .section3 .black-wrapper .title {
        margin-top: 0px;
        margin-bottom: 0px;
        justify-content: start;
    }


    .section3 .image-wrapper {
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .section3 .image-wrapper .row {
        margin:0;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .section3 img {
        width: 35px;
    }

    .section3 .image-wrapper .col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section3 .clients-partners-wrapper p {
        margin-top: 0px;
        margin-bottom: 0px;
        
    }
    
    .section3 .clients-partners-wrapper {
        flex-direction: row;
    }

    .clients-partners-wrapper p:not(.number) {
        color: #8A8A8A;
    }

    .clients-wrapper {
        align-items: end !important;
    }

    .partners-wrapper {
        align-items: start !important;
    }

    .number {
        font-size: 25px;
        font-weight: bold;
    }

    .section4 .hear-from-wrapper {
        display: flex;
        flex-direction: column;
    }

    .section4{
        margin-bottom: 10%;
    }
    
    .section4 .hear-from-wrapper .col {
        width: 100%;
    }

    .section4 .hear-from-wrapper p {
        display: none;
    }

    .section5 {
        margin-bottom: 10%;
    }
}