.service-item-wrapper{
    padding-left: 2rem;
    padding-right: 2rem;

}

.service-item-img-wrapper {
    width: 55px;
    height: 55px;
}

.service-item-img-wrapper  img {
    width: 100%;
    height: 100%;
}

.service-item-name{
    font-size: 20px;
}

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .service-item-wrapper{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .service-item-wrapper{
        padding: 0rem;
        margin: 0rem;
    }

    .service-item-img-wrapper {
        width: 35px;
        height: 35px;
    }
    .service-item-name{
        font-size: 13px;
    }
}