.list {
    padding-left: 1rem;
}

.list li {
    list-style: disc;
}

.list li span {
    font-family: "wfont1";
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
}

hr {
    opacity: 0.5;
}

.h4-wrapper h4 {
    margin-right: 10px;
}

.h4-wrapper {
    display: flex;
    align-items: center;
}

.h4-wrapper::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: black;
    margin-left: 10px;
}

.black-wrapper {
    background-color: black;
    color: white;
}

.project-title-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.project-content-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.project-btn-container {
    text-align: center;
}

.project-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-content h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.project-content p {
    font-family: "wfont1";
}

.growth-wrapper {
    margin-top: 2rem;
    text-align: center;
}

.growth-wrapper p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.black-wrapper h6 {
    margin-top: 0px;
}