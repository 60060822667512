.form-label {
    font-family: "wfont3";
}

.form-label-required:after {
    content: "*";
    margin-left: 5px;
}

.form-control {
    background-color: #E0E0E0;
    border: 2px solid #E0E0E0;
    border-radius: 0;
    font-family: "wfont1";
}

.form-control:focus {
    border: 2px solid black;
    box-shadow: none;
}

.row-between-cols {
    margin-bottom: 20px;
}

textarea::placeholder {
    color: #b6b2b2 !important;
    justify-content: center;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.contact-form{
    margin: 3rem;
}
.contact-form button {
    background-color: black;
    border-radius: 0px;
    padding: 8px 50px;
    color: #FFFEFB;
    border: 1px solid #33343F;
}

.contact-form button:hover{
    background-color: black;
    color: #FFFEFB;
    border: 1px solid #33343F;
}


.subject-btn-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.readonly-input {
    pointer-events: none;
}

.contact-form-container img{
    /* width: 300px; */
    height: 100%;
    object-fit: cover;
    object-position: 80% 100%;
}

.contact-form-hr-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-control[type="textarea"] {
    height: 150px;
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .form-label {
        font-size: 13px;
        margin-bottom: 0px;
    }

    .form-control {
        font-size: 13px;
    }

    .form-control[type="textarea"] {
        height: 100px;
    }

    .contact-form{
        margin: 0px;
    }

    .contact-form-wrapper{
        justify-content: center;
    }

    .subject-btn-wrapper{
        justify-content: center;
    }
}