.service-section1 {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 0px;
}

.offer-title-wrapper {
    /* margin: 1rem; */
    padding: 0.5rem;
    border-right: 1px solid black;
    position: relative;
}

.offer-title-wrapper div {
    margin-top: 4rem;
}

.offer-title-wrapper::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 100%;
    /* Adjust the percentage value as needed */
    transform: translateX(-50%);
    width: 8px;
    /* 동그라미의 지름 */
    height: 8px;
    /* 동그라미의 지름 */
    background-color: black;
    /* 동그라미의 색상 */
    border-radius: 50%;
    /* 원형 모양으로 만듦 */
}

.offer-wrapper {
    margin: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.service-section2 {
    position: relative;
    z-index: 0;
    overflow: hidden;
}
/* .service-section2::after  */
.service-section2::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 7%;
    background-color: black;
    z-index: -1;
}

.service-section2::before {
    top: 0;
}

.service-section2::after {
    bottom: 10%;
}

.service-section2 .top-text,
.service-section2 .bottom-text {
    position: relative;
    z-index: 1;
    color: white;
}

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .service-section1 {
        display: flex;
        flex-direction: column;
        margin-top: 10%;
    }

    .offer-title-wrapper {
        border-right: none;
    }

    .offer-title-wrapper div {
        margin-top: 0px;
    }

    .offer-title-wrapper::after {
        display: none;
    }

    .offer-wrapper {
        margin: 0px;
    }

    .service-section2 {
        position: initial;
        z-index: initial;
        overflow: initial;
    }

    .service-section2::before,
    .service-section2::after {
        content: initial;
        position: initial;
        left: initial;
        right: initial;
        height: initial;
        background-color: initial;
        z-index: initial;
    }

    .service-section2::before {
        top: initial;
    }

    .service-section2::after {
        bottom: initial;
    }

    .service-section2 .top-text,
    .service-section2 .bottom-text {
        position: initial;
        z-index: initial;
        color: initial;
    }
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .service-section1 {
        display: flex;
        flex-direction: column;
        margin-top: 10%;
    }

    .offer-title-wrapper {
        border-right: none;
    }

    .offer-title-wrapper div {
        margin-top: 0px;
    }

    .offer-title-wrapper::after {
        display: none;
    }

    .offer-wrapper {
        margin: 0px;
    }

    .service-section2 {
        position: initial;
        z-index: initial;
        overflow: initial;
    }

    .service-section2::before,
    .service-section2::after {
        content: initial;
        position: initial;
        left: initial;
        right: initial;
        height: initial;
        background-color: initial;
        z-index: initial;
    }

    .service-section2::before {
        top: initial;
    }

    .service-section2::after {
        bottom: initial;
    }

    .service-section2 .top-text,
    .service-section2 .bottom-text {
        position: initial;
        z-index: initial;
        color: initial;
    }

}