h6{
    margin-bottom: 1.5rem;
}

h6 span{
    background-color: #ebe9e9;
    font-weight: bold;
}

.even-row {
    text-align: right;
}


.employee-wrapper{
    align-items: center;
}

.employee-wrapper.odd{
    display: flex;
    justify-content: flex-start;
}

.employee-wrapper.even{
    display: flex;
    justify-content: flex-end;
}

.employee-img-wrapper img{
    width: 300px;
    height: auto;
    
}
.phone-email-wrapper{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0.5rem;
}

.department-position-wrapper{
    margin-bottom: 1.5rem;
}

.department-position-wrapper p,
.phone-email-wrapper p{
    margin-top: 0px;
    margin-bottom: 0px;
}