

.step:last-child .arrow-wrapper {
    display: none;
}

.arrow-wrapper {
    margin-bottom: 1rem;
}

.process-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.process-wrapper p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    border: 1px solid black;
    padding: 1rem 1rem;
}

.web-process-wrapper{
    display: flex;

    .process-number{
        display: flex;
        justify-content: center;
        font-size: xx-large;
        font-weight: bold;
    }
}


.web-process-img-wrapper {
    height: 100%; /* 부모 요소의 높이를 100%로 설정 */
    position: relative; /* 필요에 따라 position 설정 */

    img{
        width: 100%;
        height: 100%;
        object-fit: cover; /* 이미지가 부모 요소를 꽉 채우도록 설정 */
    }
}

.web-process-content-wrapper{
    display: flex;
    flex-direction: column;

    .process-title{
        font-family: "wfont3" !important;
        display: flex;
    }
    
    p{
        font-family: "wfont1";
    }
}


/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .process-container {
        text-align: center;
    }
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .process-container {
        text-align: center;
    }
}