.service-container{
    border: 1px solid black;
    padding: 1.5rem;
    display: grid;
    justify-content: center;
}

.service-container p{
    margin: 0;
    font-family: "wfont1";
}

.service-title{
    font-family: "wfont3" !important;
    padding-bottom: 1rem;
}