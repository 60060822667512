
ul,
li {
  list-style: none;
}

.wrapper {
  .slide_container {
    overflow: hidden;

    .slide_wrapper {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      position: relative;
      text-align: center;

      &.original {
        animation: 60s linear infinite normal none running infiniteAnimation1;
      }

      &.clone {
        animation: 60s linear infinite infiniteAnimation2;
      }

      &.stop {
        animation-play-state: paused;
      }

      li {
        margin:0  30px;
        cursor: pointer;
        z-index: 2;
        transition: 0.3s;
        transform: scale(1);

        &:hover {
          transform: scale(0.98);

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }

        .item {
          height: 100%;
        }
      }
    }
  }
}

.partners-title{
  border-right: 1px solid black;
}

@keyframes infiniteAnimation1 {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-100%);
  }

  50.1% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes infiniteAnimation2 {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
  .slide_wrapper{
    background-color: black;
    color: white;
    width: 100vw;
  }

  .slide_wrapper .flow-wrap p {
    font-weight: bold;
  }

  .partners-title{
    border-right: 1px solid white;
  }
}