.h5-wrapper {
    display: flex;
    align-items: center;
}

.h5-wrapper::before {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: black;
    margin-right: 10px;
}

.gray-wrapper{
    background-color: #f0f0f0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.support-wrapper{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.support-wrapper.row{
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .support-wrapper img{
        width: 20px;
        
    }

    .support-wrapper{
        display: flex;
        flex-direction: column;
    }

    .support-wrapper .row{
        display: flex;
        flex-direction: row;
    }

    .support-wrapper .col{
        align-items: self-start;
    }
}