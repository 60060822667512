/* Desktop Screen */
main {
  display: grid;
  margin: auto;
  width: 100%;
  /* justify-content: center; */
}

.Layout {
  width: 100%;
  display: grid;
  grid-template-rows: auto 3fr auto;
  /* 헤더, 콘텐츠, 푸터의 높이를 자동, 콘텐츠의 남은 공간, 푸터 순서대로 설정 */
  min-height: 100vh;
  /* 최소 높이를 브라우저 높이와 같게 설정하여 콘텐츠가 창보다 적어도 창을 가득 채우도록 합니다. */
}

.container {
  max-width: 1280px;
}

header,
footer {
  display: grid;
}

.btn-primary {
  background-color: black;
  color: white;
}


.btn:first-child:active {
  background-color: black;
  color: white;
}

@font-face {
  font-family: 'wfont1';
  src: url("../assets/font/file_1.woff2") format("woff2")
}

@font-face {
  font-family: 'wfont2';
  src: url("../assets/font/file_2.woff2") format("woff2")
}

@font-face {
  font-family: 'wfont3';
  src: url("../assets/font/file_3.woff2") format("woff2")
}

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 100vw;
  }
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
  .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    padding-right: calc(var(--bs-gutter-x));
    padding-left: calc(var(--bs-gutter-x));

  }

  header,
  footer {
    /* grid-template-columns: 1fr;  */
  }
}

/* 웹 화면에서 아이템 가로 정렬 */
@media screen and (min-width: 769px) {

  header,
  footer {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* 아이템을 가로로 정렬하고, 자동으로 크기를 조절합니다. */
  }
}