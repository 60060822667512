/* Desktop Screen */
.web-bottom {
    color: white;
}

.web-bottom .row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.web-bottom img {
    width: 120px;
}

footer {
    background-color: black;
}

footer #logo {
    margin-top: 5%;
    margin-bottom: 5%;
    margin: auto;
    text-align: center;
}

footer p {
    color: #fff;
    line-height: 1.79em;
    font-size: 14px;
}

.footer-link {
    font-family: "wfont1";
    color: white;
    text-decoration: none;
}

footer .footer-link:hover {
    color: white;
    font-weight: bold;
}

.divider {
    margin: 0 auto;
    opacity: 0.5;
    border-top: 1px solid #fff;
}

.web-bottom-second-content {
    display: flex;
    justify-content: center;
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    footer {
        width: 100vw;
        background-color: white;
        box-shadow: 0px -1px 5px 0px #000000;
    }

    footer .row {
        text-align: center;
    }

    footer .footer-link {
        font-size: 13px;
        /* font-weight: bold; */
        white-space: nowrap;
        color: black;
    }

    footer .footer-link:hover {
        color: black;
        font-weight: bold;
    }

    footer.fixed-bottom {
        position: fixed;
        bottom: 0;
        flex-shrink: 0;
        width: 100%;
        background-color: white;
        box-shadow: 0px -1px 5px 0px #000000;
        z-index: 999;
        /* 다른 요소들 위에 위치 */

    }

    /* 스크롤 위로 올라가는 버튼 스타일 */
    .scroll-to-top {
        display: none;
        /* 기본적으로 숨김 */
        position: fixed;
        bottom: 20px;
        /* footer 위에 배치 */
        right: 20px;
        background-color: #E0E0E0;
        color: white;
        border: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: pointer;
        z-index: 1000;
    }

    /* 버튼이 보여질 때 */
    .scroll-to-top.show {
        display: flex;
    }

    /* 버튼 hover 스타일 */
    .scroll-to-top:hover {
        /* background-color: #E0E0E0; */
        color: #E0E0E0;
        background-color: transparent;
    }


}