.accordion{
    --bs-accordion-active-bg: white;
    --bs-accordion-active-color: black;
    --bs-accordion-btn-focus-border-color: black;
    --bs-accordion-btn-focus-box-shadow: none;
}

.accordion-header button::after{
    color: white;
}

.accordion-body {
    background-color: black;
    color: white;
}

.video-wrapper{
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
}

.video-wrapper .col{
    width: 100%;
    display: flex;
}


.video-wrapper.vertical .col-lg-4 {
    flex: 1 1 20%; 
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.custom-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .video-wrapper.vertical .col-lg-4 {
        flex: 1 1 50%; 
    }

    .custom-play-button {
        font-size: 36px; /* 모바일 화면에서의 크기 조정 */
        padding: 10px 20px; /* 모바일 화면에서의 버튼 크기 조정 */
    }

}