/* Desktop Screen */
nav {
    box-shadow: black 0px 0px 10px;
    background-color: none;
}

.grid-container {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
    max-width: 2200px;
}

.navbar-brand {
    margin-right: 0px;
}

.navbar-brand img {
    max-width: 149px;
}

.navbar>.container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-evenly;
}

.navbar-toggler {
    border: none;
}

.offcanvas.offcanvas-end {
    width: var(--bs-offcanvas-width);
}

.offcanvas {
    --bs-offcanvas-padding-x: 2rem;
    --bs-offcanvas-padding-y: 2rem;
    --bs-offcanvas-width: 300px;
}

.nav-link {
    padding: 1rem 0;
    font-family: "wfont1";
}

.nav-link:hover {
    font-weight: bold;
}

.nav-link:active {
    font-weight: bold;
}

/* Dropdown css */
/* 초기에 숨김 */
.hover-dropdown .dropdown-menu {
    display: none;
}

/* 호버 시에 표시 */
.hover-dropdown:hover .dropdown-menu {
    display: block;
}

.custom-dropdown .dropdown-title {
    pointer-events: none;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    /* border-right: .3em solid transparent; */
    border-bottom: 0;
    /* border-left: .3em solid transparent; */

    border-right: none;
    border-left: none;
}

.dropdown-menu {
    transform: translateX(-30%);
}


.dropdown-menu {
    border: none;
    font-size: 16px;
    text-align: center;
    --bs-dropdown-link-hover-bg: black;
    --bs-dropdown-link-hover-color: white;
    --bs-dropdown-link-active-bg: black;
    --bs-dropdown-link-active-color: white;
}

.nav-link {
    font-family: "wfont1";
    text-decoration: none;
    color: #212529;
}

.nav-link:hover {
    color: black;
    font-weight: bold;
}

.mobile-nav-link {
    text-align: center;
    font-size: 13px;
    white-space: nowrap;
}

/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar>.container-fluid {
        justify-content: space-around;
    }
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    nav {
        /* box-shadow: none; */
        background-color: none;
    }

    .navbar>.container-fluid {
        justify-content: space-between;
    }

    /* Logo */
    .navbar-brand {
        display: flex;
        justify-content: center;
    }

    .navbar-brand img {
        max-width: 100px;
    }

    .nav-link {
        font-size: 20px;
    }

    .mobile-nav-link {
        text-align: center;
        font-size: 13px;
        white-space: nowrap;
    }

    /* Dropdown menu */
    .offcanvas-body {
        font-size: 25px;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        --bs-offcanvas-padding-y: 1rem;
        /* --bs-offcanvas-padding-x: 1rem; */
    }

    .container-fluid {
        display: grid;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;

        /* border-right: none; */
        /* border-left: none; */
    }

    .dropdown-menu {
        font-family: "wfont1";
        border: none;
        font-size: 20px;
        text-align: left;
    }

    .dropdown-menu a:not(:last-child) {
        margin-bottom: 1rem;
    }

    .dropdown-item:active {
        background-color: white;
        font-weight: bold;
        color: #212529;
    }

}