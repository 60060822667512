.link-button a{
    background-color: black;
    border-radius: 0px;
    padding: 1em 3em;
    color: #FFFEFB;
    border: 1px solid #33343F;
    text-decoration: none;
    font-size: 16px;
    /* font-weight: bold; */
}

.clink-button a:hover{
    color: #FFFEFB;
    color: #33343F;
    border: 1px solid #33343F;
}


.linkbutton-container{
    text-align: center;
    /* margin-top: 5%; */
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
    .link-button a{
        font-size: 13px;
    }
}