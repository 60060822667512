.project-button {
    padding: 8px 2rem;
    border: 2px solid black;
    text-decoration: none;
    margin: 5px;
    font-size: 13px;
    font-family: "wfont1";
}

.project-button:hover {
    background: black;
    color: #FFFEFB;
    border: 2px solid black;
}

.project-btn-container {
    gap: 10px;
}

/* Mobile Screen */
@media screen and (max-width: 767px) {
    .project-button {
        padding: 8px 1.5rem;
    }
}