.carousel-item{
    padding-left: 7rem;
    padding-right: 7rem;
    margin-bottom: 0px;
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
    .client-partners-carousel-container{
        display: flex;
        flex-direction: column;
    }
    
    .carousel-control-next, .carousel-control-prev{
        align-items: flex-start;
        padding-top: 1rem;
        width: 55%;
        justify-content: space-around;
    }
    
    .carousel-item {
        padding-left: 0rem;
        padding-right: 0rem;
        margin-bottom: 0px;
    }
}